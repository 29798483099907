import React from "react";
import styled from "styled-components";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";

const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bgLighter};
  height: 56px;
  padding: 2rem;
  border-bottom: 1px solid #30363D;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0px 20px;
  position: relative;
  color: ${({ theme }) => theme.text};
`;

const Socials = styled.div`
  align-items: center;
  justify-content: flex-end;
`;


const SocialLink = styled(Link)`
  text-decoration: none;
  margin-right: 20px;
  color: white;
`;

const Button = styled.button`
  padding: 6px 15px;
  background-color: transparent;
  border: 1px solid #30363D;
  color: #ffffff;
  border-radius: 2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  margin-left: 10px;

  &:active {
    color: ${({ theme }) => theme.bg};
  }

`;

function copyToClipboard(text) {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
}

export function showNotification() {
  const notification = document.createElement('div');
  notification.textContent = 'Token copied!';
  notification.classList.add('notification');
  document.body.appendChild(notification);

  setTimeout(() => {
    notification.remove();
  }, 2000); // Remove notification after 2 seconds
}

export function copyTokenToClipboard() {
  const tokenElement = document.querySelector('.token');

  if (tokenElement) {
    tokenElement.addEventListener('click', () => {
      const tokenText = tokenElement.textContent.trim();
      copyToClipboard(tokenText);
      showNotification();
    });
  }
}


const Navbar = () => {

  const copyToken = () => {
    const tokenElement = document.querySelector('.token');
    const tokenText = tokenElement.textContent.trim();
    copyTokenToClipboard(tokenText);
  };


  return (
    <Container>
      <Wrapper>
        <Socials>
          <SocialLink to="https://twitter.com/catubesolana">
          <TwitterIcon style={{ fontSize: "35px"}} />
          </SocialLink>
          <SocialLink to="https://t.me/catubesolana">
          <TelegramIcon style={{ fontSize: "35px"}} />
          </SocialLink>
        </Socials>
        <Button  className="token" onClick={copyToken}>

          </Button>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
