// imageDataContext.js
import React, { useState, createContext, useContext } from 'react';

const ImageDataContext = createContext();

export const ImageDataContextProvider = ({ children }) => {
  const [imageData, setImageData] = useState(null);

  return (
    <ImageDataContext.Provider value={{ imageData, setImageData }}>
      {children}
    </ImageDataContext.Provider>
  );
};

export const useImageDataContext = () => useContext(ImageDataContext);
