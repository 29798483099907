import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import { imageList } from '../utils/imageConstants';


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Home = () => {
  return (
    <Container>
      {imageList.map((imageData, index) => (
        <Card imageData={imageData} key={index} />
      ))}
    </Container>
  );
};

export default Home;
