import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import YouTube from "../img/logo2.png";
import { useImageDataContext } from '../utils/imageDataContext';


const Container = styled.div`
  width: ${(props) => props.type !== "sm" && "378px"};
  margin-bottom: ${(props) => (props.type === "sm" ? "10px" : "45px")};
  margin-right: ${(props) => (props.type === "sm" ? "2px" : "3px")};
  cursor: pointer;
  display: ${(props) => props.type === "sm" && "flex"};
  gap: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: ${(props) => (props.type === "sm" ? "120px" : "230px")};
  background-color: #999;
  flex: 1;
  border-radius: .8rem;
`;

const Details = styled.div`
  display: flex;
  margin-top: ${(props) => props.type !== "sm" && "16px"};
  gap: 12px;
  flex: 1;
`;

const ChannelImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #000;
  display: ${(props) => props.type === "sm" && "none"};
`;

const Texts = styled.div``;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
`;

const Card = ({ type, imageData }) => {
  const { setImageData } = useImageDataContext();

  const handleClick = () => {
    setImageData(imageData);
  };

  return (
    <Link to={ "/video/"+ imageData?.yv}  onClick={handleClick} style={{ textDecoration: "none" }}>
      <Container className='card-container' type={type}>
        <Image className='card-image'
          type={type}
          src = {imageData?.src}

        />
        <Details type={type}>
          <ChannelImage
            type={type}
            src={YouTube}
          />
          <Texts>
            <Title>{imageData?.title}</Title>
          </Texts>
        </Details>
      </Container>
    </Link>
  );
};

export default Card;
