import yv1 from '../img/youtube/yv1.jpg';
import yv2 from '../img/youtube/yv2.jpg';
import yv3 from '../img/youtube/yv3.jpg';
import yv4 from '../img/youtube/yv4.jpg';
import yv5 from '../img/youtube/yv5.jpg';
import yv6 from '../img/youtube/yv6.jpg';
import yv7 from '../img/youtube/yv7.jpg';
import yv8 from '../img/youtube/yv8.jpg';
import yv9 from '../img/youtube/yv9.jpg';
import yv10 from '../img/youtube/yv10.jpg';
import yv11 from '../img/youtube/yv11.jpg';
import yv12 from '../img/youtube/yv12.jpg';


export const imageList = [
  {
    src: yv1,
    title: '😅 Funniest Cats 😻🐶',
    yv: 'O7q41f7yl-E?si=kMZjkaPf85I0zdFw',
  },
  {
    src: yv2,
    title: 'Ultimate Funny Cats and Dogs 😻🐶 Funniest Animals 😂',
    yv: 'q_E0CquIt5o?si=vvyVpsafI7dbEDPw',
  },
  {
    src: yv3,
    title: 'You Laugh You Lose 😅 Funniest Cats and Dogs 2023 😻🐶',
    yv: '0OIpwFgCRxY?si=-1kOfI6vdZbOsdK9',
  },
  {
    src: yv4,
    title: 'My cute tabby kitten loved playing with my shoes',
    yv: 'M1YxncM7AHc?si=uzcarLDB9iJ4S7Ij',
  },
  {
    src: yv5,
    title: 'The kitten approaching the daddy cat to play with him was so cute',
    yv: 'wE8s993ZV-8?si=Qn2i18WYX98HJJtN',
  },
  {
    src: yv6,
    title: 'Minuet Short Legs Short Hair Red Tabby & White Girl',
    yv: '4lXaUqxRfiE?si=HxF2s-ZLDmR4qMbS',
  },
  {
    src: yv7,
    title: 'New Funny Videos 2024 😍 Cutest Cats and Dogs',
    yv: 'LpcYwavMFEc?si=aVgd9ofWDDnYQOFg',
  },
  {
    src: yv8,
    title: 'Try Not To Laugh 🤣 New Funny Cats and Dogs Videos',
    yv: 'IuKh4meT1Cs?si=ovzjJOsMEFoJkm2e',
  },
  {
    src: yv9,
    title: 'Silliest CATS on Earth 😂 Funny Cats Vids',
    yv: 'RFMuw3xpmFE?si=PyLTw27r0srZF404',
  },
  {
    src: yv10,
    title: 'CATS you will remember and LAUGH all day! 😂Funny Cats Videos',
    yv: 'IzluNxh-8_o?si=M92noHlv5CM1XgLv',
  },
  {
    src: yv11,
    title: 'Funny Cat Videos of 2024 😍 Try not to laugh',
    yv: '9VC_5Cxg1NM?si=XsS6AMmdQxflw5HA',
  },
  {
    src: yv12,
    title: 'Funniest Cats and Dogs 🐶🐱',
    yv: 'ChH3mgE8JWw?si=5hXPq1fQBYfQU6o1',
  },
];
